import {
  GET_LESSON,
  GET_LESSONS,
  SET_LOADING,
  GET_QUESTION,
  GET_QUESTIONS,
  SET_LESSON,
  SET_QUESTION,
  SET_CURRENT_QUESTION_INDEX
} from "../types"

export default ( state, action ) => {

  switch( action.type ) {

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case GET_LESSONS:
      return {
        ...state,
        lessons: action.payload.data,
        loading: false,
        netxLessonAvailable: action.payload.netxLessonAvailable,
        interestId: action.payload.interestId
      }
    case GET_LESSON:
      return {
        ...state,
        currentLesson: action.payload
      }
    case SET_LESSON:
      return {
        ...state,
        currentLesson: action.payload
      }
    case SET_QUESTION: 
      return {
        ...state,
        currentQuestion: action.payload
      }
    case GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload
      }
    case GET_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload
      }
    case SET_CURRENT_QUESTION_INDEX:
      return {
        ...state,
        currentQuestionIndex: action.payload
      }
    default:
      return state
      
  }
}