// import react
import React, { useContext, useEffect, Fragment } from 'react';

import LessonContext from '../../Context/LessonContext/lessonContext'

// import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';

const ListLessons = (props) => {

  const lessonsContext = useContext(LessonContext)
  const { lessons, getLessons, setLesson, netxLessonAvailable } = lessonsContext

  useEffect(() => {
    getLessons(props.interestId)
  }, [])

  const handleClick = ( index ) => {
    setLesson(lessons[index])
    // console.log(lessons, index)
  }

  return (
    <Fragment>
      <List>
        {
          lessons.map( ( l, i ) => (
            <ListItem button key={i} disabled={l.done === 0 && i !== netxLessonAvailable } onClick={() => handleClick(i)}>
              <ListItemIcon>
                {
                  l.done ?
                    <DoneIcon />
                  :
                    <CloseIcon />
                }
              </ListItemIcon>
              <ListItemText primary={`Lección ${i+1}`} />
            </ListItem>
          ))
        }
      </List>
    </Fragment>
  )
}

export default ListLessons;
