import React, { createContext } from 'react';

import LessonState from './LessonContext/LessonState'

const GeneralContext = createContext();

const { Consumer, Provider } = GeneralContext;

export function GeneralProvider({ children }) {
  return (
    <Provider>
      <LessonState>
        
        { children }

      </LessonState>
    </Provider>
  )
}
