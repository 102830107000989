import React, { Fragment, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const Home = () => {
  const classes = useStyles();
  const [interestId, setInterestId] = useState(null)

  const handleChange = (e) => {
    setInterestId(e.target.value)
  }

  const handleLogin = (e) => {
    e.preventDefault();
    window.location = `/estudio/${interestId}`
  }

  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar>
        
          <Typography variant="h6">
            Fé de Jesús
          </Typography>
          
        </Toolbar>
      </AppBar>
      
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <MenuBookIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingresa con tu numero de estudio
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="interestId"
              label="Número de estudio"
              name="interestId"
              autoFocus
              value={interestId}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleLogin}
              disabled={interestId ===  null || interestId.length === 0}
            >
              Ingresar
            </Button>
          </form>
        </div>
      </Container>
    </Fragment>
  );
}

export default Home;
