import React, { useReducer } from 'react'
import LessonContext from './lessonContext'
import lessonReducer from './lessonReducer'
import {
  GET_LESSON,
  GET_LESSONS,
  SET_LOADING,
  GET_QUESTION,
  SET_LESSON,
  GET_QUESTIONS,
  SET_QUESTION,
  SET_CURRENT_QUESTION_INDEX
} from '../types'

import axios from 'axios'

const LessonState = props => {

  const initialState = {
    loading: true,
    lessons: [],
    currentLesson: {},
    netxLessonAvailable: null,
    questions: [],
    currentQuestion: {},
    answers: [],
    currentQuestionIndex: null,
    interestId: 0
  }

  const [ state, dispatch ] = useReducer( lessonReducer, initialState )

  const setLoading = ( loading ) => {
    dispatch({
      type: SET_LOADING,
      payload: loading
    })
  }

  // GET Lessons
  const getLessons = async ( interestId ) => {
    const metadata = await axios.get(`https://api.iglesiazambrano.mx/interest-endpoint/${interestId}?meta=true`)

    axios.get( `https://api.iglesiazambrano.mx/lessons-endpoint?course=1&interestId=${interestId}` )
    .then( res => {
      res.data.reverse()
      let idx = res.data.findIndex(d => d.done === 1)
      idx = idx === -1 ? 0 : res.data.length - idx
      dispatch({
        type: GET_LESSONS,
        payload: {
          data:res.data.reverse(),
          netxLessonAvailable: idx,
          interestId: metadata.data[0].id
        }
      })
    })
    .catch( err => console.log( `ERR GET LESSONS ==>`, err ) )
  }

  // SET Lesson
  const setLesson = ( lesson ) => 
    dispatch({
      type: SET_LESSON,
      payload: lesson
    })

  // GET Questions
  const getQuestions = ( lessonId ) => {
    axios.get(`https://api.iglesiazambrano.mx/lessons-endpoint/${lessonId}?clean=true`)
      .then(res => {
        dispatch({
          type: GET_QUESTIONS,
          payload: res.data.questions
        })
        getQuestion(res.data.questions[0])
        setCurrentQuestionIndex(0)
      })
      .catch(err => console.log (`ERR GET Questions ==>`, err ) )
  }
  
  // GET Question
  const getQuestion = ( questionId ) => {
    axios.get( `https://api.iglesiazambrano.mx/questions-endpoint/${ questionId }` )
    .then(res => {
      dispatch({
        type: GET_QUESTION,
        payload: res.data
      })
    })
    .catch(err => console.log (`ERR GET QUESTION ==>`, err ) )
  }

  //SET Question
  const setQuestion = ( question ) => 
    dispatch({
      type: SET_QUESTION,
      payload: question
    })

  // SET Current Question index
  const setCurrentQuestionIndex = ( index ) =>
    dispatch({
      type: SET_CURRENT_QUESTION_INDEX,
      payload: index
    })

  //SET lesson as finished
  const setLessonFinished = async (lessonId, interestId) => {
    const isFinished = await axios.get(`https://api.iglesiazambrano.mx/checkpoints?interestId=${interestId}&lessonid=${lessonId}`)
    console.log("isFinished =>", isFinished)
    if(isFinished.data.total === 0 || isFinished.data.data[0].active === 0 ) {
      const res = await axios.post(`https://api.iglesiazambrano.mx/checks`, {
        interestId,
        lessonId,
        course: 1
      })
    }
  }

  return <LessonContext.Provider
    value={{
      netxLessonAvailable: state.netxLessonAvailable,
      loading: state.loading,
      lessons: state.lessons,
      currentLesson: state.currentLesson,
      questions: state.questions,
      answers: state.answers,
      currentQuestion: state.currentQuestion,
      currentQuestionIndex: state.currentQuestionIndex,
      interestId: state.interestId,
      setLoading,
      getLessons,
      getQuestion,
      setLesson,
      getQuestions,
      setQuestion,
      setCurrentQuestionIndex,
      setLessonFinished
    }}
  >
    { props.children }
  </LessonContext.Provider>
}

export default LessonState
