import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { GeneralProvider } from "./Context/GeneralContext"

import Home from './Components/Home'
import Estudio from './Components/Estudio'

const AppRoutes = () => (
  <GeneralProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/estudio/:id" component={Estudio} />
        </Switch>
      </BrowserRouter>
  </GeneralProvider>
)

export default AppRoutes;