import React, { useState, useEffect, Fragment, useContext } from 'react';

import LessonContext from '../../Context/LessonContext/lessonContext'

// import MaterialUI components
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Swal from "sweetalert2";

const Lesson = () => {

  const lessonContext = useContext(LessonContext)

  const { 
    currentLesson,
    questions,
    getQuestions,
    setQuestion,
    currentQuestion,
    getQuestion,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    setLessonFinished,
    interestId
  } = lessonContext

  const [isNextQuestionDisabled, setIsNextQuestionDisabled] = useState(true)
  const [isLastQuestion, setIsLastQuestion] = useState(false)

  useEffect(() => {
    if (Object.keys(currentLesson).length > 0) {
      getQuestions(currentLesson.id)
    }
  }, [currentLesson])

  useEffect(() => {
    if (currentQuestionIndex !== null && currentQuestionIndex !== undefined) {
      getQuestion(questions[currentQuestionIndex])
    }
  }, [currentQuestionIndex])

  useEffect(() => {
    if (currentQuestion.resolution === 1) {
      setIsNextQuestionDisabled(false)
    }
  }, [currentQuestion])

  const [verse, setVerse] = React.useState('');

  React.useMemo(() => {

    const call = `https://api.biblia.com/v1/bible/content/RVR60.txt.txt?passage=${currentQuestion.verse}&callback=myCallbackFunction&key=fd37d8f28e95d3be8cb4fbc37e15e18e`

    fetch(call).then(response => response.text())
      .then(data => { setVerse(data); });

  }, [currentQuestion])

  const handleCardClick = (index) => {
    const isCorrect = currentQuestion && currentQuestion.answers && currentQuestion.answers[index] && currentQuestion.answers[index].isCorrect === 1 ? true : false;
    setIsNextQuestionDisabled(!isCorrect)
    const tempAnswers = currentQuestion && currentQuestion.answers ? currentQuestion.answers : []
    const newAnswers = tempAnswers.map((a, i) => (
      {
        id: a.id,
        answer: a.answer,
        isCorrect: a.isCorrect,
        questionind: a.questionid,
        className: i === index ? (a.isCorrect === 1 ? "rightAnswer" : "wrongAnswer") : ""
      }
    ))

    const tempCurrentQuestion = { ...currentQuestion }
    tempCurrentQuestion.answers = newAnswers;

    setQuestion(tempCurrentQuestion)

  }

  const handleNextQuestion = () => {
    if (isLastQuestion) {
      setLessonFinished(currentLesson.id, interestId)
      Swal.fire({
        title: "Ha terminado la lección correctamente",
        icon: "success",
        timer: 2000
      });

    } else {
      setIsNextQuestionDisabled(true)
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      if (questions.length - 1 === currentQuestionIndex + 1) {
        setIsLastQuestion(true)
      }
    }
  }

  const TitleGrid = styled(Grid)`
    display:flex;
    flex-direction: column;
  `

  const Verse = styled(Link)`
    padding-left: 1em;
    cursor: pointer;
  `

  const [openVerseModal, setOpenVerseModal] = React.useState(false)

  return (
    <Fragment>

      <Grid container spacing={3} justify="flex-end">

        <TitleGrid item xs={12}>
          {
            currentQuestion.resolution === 1 &&
            <Typography component="h1" variant="h2" gutterBottom>
              ¿Qué debo hacer?
            </Typography>
          }
          <Typography component="h1" variant="h4" gutterBottom>
            {currentQuestion.question}
          </Typography>

          <Verse onClick={() => setOpenVerseModal(true)} >{currentQuestion.verse ? currentQuestion.verse.toUpperCase() : ""}</Verse>

          <Dialog
            open={openVerseModal}
            onClose={() => setOpenVerseModal(false)}
          >
            <DialogTitle>{currentQuestion && currentQuestion.verse ? currentQuestion.verse.toUpperCase() : ""}</DialogTitle>
            <DialogContent>
              <DialogContentText> {'--'} "{verse}"</DialogContentText>
            </DialogContent>
          </Dialog>

        </TitleGrid>

        {
          currentQuestion && currentQuestion.answers && currentQuestion.answers.map((a, i) => (
            <Grid item xs={12} md={3} key={i} >
              <Card variant="outlined" onClick={() => handleCardClick(i)} >
                <ButtonBase style={{ width: '100%' }} className={a.className} >
                  <CardContent>
                    {a.answer}
                  </CardContent>
                </ButtonBase>
              </Card>
            </Grid>
          ))
        }

        {
          Object.keys(currentQuestion).length === 0 &&
          <TitleGrid item xs={12}>
            <Typography variant="h4">
              Seleccione una lección
            </Typography>
          </TitleGrid>
        }

        {
          currentQuestion && currentQuestion.answers &&
          <Grid item>
            <Button variant="contained" color="primary" disabled={isNextQuestionDisabled} onClick={handleNextQuestion} >
              {
                isLastQuestion ? "Terminar" : "Siguiente"
              }
            </Button>
          </Grid>
        }

      </Grid>

    </Fragment>

  )

}

export default Lesson;
